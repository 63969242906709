import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

export const PageLoading = () => {
  return (
    <LoadingWrapper>
      <LoadingContent>
        <CircularProgress size={100} sx={{ color: '#141d49' }} />
        <TextContent>Loading</TextContent>
      </LoadingContent>
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

const LoadingContent = styled.div`
  margin: auto;
  color: #141d49;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const TextContent = styled.div`
  margin-top: 20px;
  font-size: 40px;
  font-weight: 700;
`;
