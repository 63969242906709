import styled from '@emotion/styled';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { EyeOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { Button, Form, Input, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

import styles from './authen.module.css';
import { ResponseDataType, getRequest, postRequest } from 'api';
import { removeStorage } from 'utils';

const AccountPageWrapper = styled.div`
  width: 100%;
  padding: 20px;
  padding-left: 50px;
`;

const AccountEmail = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Email = styled.span`
  font-weight: 600;
  margin-left: 10px;
`;

const FormWrapper = styled.div``;

export const Account = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const ChangePassword = async (value: {
    oldPassword: string;
    newPassword: string;
  }) => {
    try {
      const response: ResponseDataType<{}> = await postRequest(
        '/auth/change-password',
        {
          oldPassword: value.oldPassword,
          newPassword: value.newPassword,
        }
      );

      if (response.code) {
        if (typeof response.message === 'object') {
          response.message.forEach((message: string) => toast.error(message));
        } else toast.error(response.message);
      } else {
        Modal.success({
          style: { fontFamily: 'Quicksand' },
          title: 'Thành công!',
          content: `Đổi mật khẩu thành công!`,
          onOk() {
            getRequest('/auth/logout');
            removeStorage();
            navigate('/login');
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AccountPageWrapper>
      <AccountEmail>
        <EmailOutlinedIcon sx={{ fontSize: '30px' }} />
        <Email>{localStorage.getItem('email')}</Email>
      </AccountEmail>
      <FormWrapper>
        <Form form={form} onFinish={(value) => ChangePassword(value)}>
          <div
            className={`${styles.form} ${styles.change_password_form} authen-form`}
          >
            <Form.Item
              name='oldPassword'
              rules={[{ required: true, message: 'Cần nhập mật khẩu cũ!' }]}
              style={{ margin: 0 }}
            >
              <Input.Password
                prefix={<EyeOutlined className={styles.icon} />}
                placeholder='Nhập mật khẩu cũ'
                className={styles.form_input}
              />
            </Form.Item>
            <div className={styles.line} />
            <Form.Item
              name='newPassword'
              rules={[
                { required: true, message: 'Cần nhập mật khẩu mới!' },
                { min: 6, message: 'Mật khẩu gồm tối thiêu 6 kí tự' },
                {
                  pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                  message: 'Mật khẩu không chứa kí tự đặc biệt và dấu cách',
                },
              ]}
              style={{ margin: 0 }}
            >
              <Input.Password
                prefix={<EyeOutlined className={styles.icon} />}
                placeholder='Mật khẩu'
                className={styles.form_input}
              />
            </Form.Item>
            <div className={styles.line} />
            <Form.Item
              name='confirmPassword'
              rules={[
                { required: true, message: 'Cần xác nhận lại mật khẩu mới!' },
                { min: 6, message: 'Mật khẩu gồm tối thiêu 6 kí tự' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    } else
                      return Promise.reject('Mật khẩu xác nhận không khớp');
                  },
                }),
              ]}
              style={{ margin: 0 }}
            >
              <Input.Password
                prefix={<EyeOutlined className={styles.icon} />}
                placeholder='Xác nhận mật khẩu mới'
                className={styles.form_input}
              />
            </Form.Item>
          </div>
          <Button
            type='primary'
            className={styles.authen_button}
            htmlType='submit'
          >
            Đổi mật khẩu
          </Button>
        </Form>
      </FormWrapper>
    </AccountPageWrapper>
  );
};
