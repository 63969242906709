import { SenderInfo } from './ChatInStream';
import { Row } from 'antd';
import styled from '@emotion/styled';
import { Avatar, MultiLineText } from 'Components';

interface ChatItemPropType {
  isNearingPrevious: boolean;
  isNearingNext: boolean;
  content: string;
  sender: SenderInfo;
}

interface ChatTextContentPropType {
  isSender: boolean;
  isNearingNext: boolean;
  isNearingPrevious: boolean;
}

export const ChatItem = (props: ChatItemPropType) => {
  const { content, sender, isNearingNext, isNearingPrevious } = props;
  const customerId = localStorage.getItem('customerId');
  const isSender = sender.customerId === customerId;

  return (
    <div style={{ margin: '0 10px', marginBottom: isNearingNext ? 0 : 15 }}>
      {!isNearingPrevious && !isSender && (
        <div style={{ margin: '0 0 3px 45px', fontSize: 13 }}>
          {sender.name}
        </div>
      )}
      <Row
        style={{ flexDirection: isSender ? 'row-reverse' : 'row' }}
        align='bottom'
      >
        {isNearingNext ? (
          <div style={{ width: 35 }} />
        ) : (
          <Avatar
            src={sender.avatar}
            style={{
              borderRadius: '50%',
              width: 27,
              height: 27,
              marginBottom: 7,
              ...(isSender ? { marginLeft: 8 } : { marginRight: 8 }),
            }}
          />
        )}
        <Row
          align='middle'
          style={{
            marginTop: 3,
            flexDirection: isSender ? 'row-reverse' : 'row',
            width: 'calc(100% - 35px)',
          }}
        >
          <ChatTextContent
            isSender={isSender}
            isNearingNext={isNearingNext}
            isNearingPrevious={isNearingPrevious}
          >
            <MultiLineText text={content} />
          </ChatTextContent>
        </Row>
      </Row>
    </div>
  );
};

const ChatTextContent = styled.div(
  ({ isSender, isNearingNext, isNearingPrevious }: ChatTextContentPropType) => `
  position: relative;
  width: max-content;
  max-width: calc(100% - 100px);
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 15px;
  word-wrap: break-word;
  background-color: ${isSender ? '#d1e3ff' : '#ececec'};
  border-bottom-left-radius: ${!isSender && isNearingNext ? '0' : '15px'};
  border-top-left-radius: ${!isSender && isNearingPrevious ? '0' : '15px'};
  border-bottom-right-radius: ${isSender && isNearingNext ? '0' : '15px'};
  border-top-right-radius: ${isSender && isNearingPrevious ? '0' : '15px'};
`
);
