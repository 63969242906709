import styled from '@emotion/styled';
import { BaseLinePropType } from './type';

interface HorizontalLinePropType extends BaseLinePropType {
  width?: string;
}

export const HorizontalLine = styled.div((props: HorizontalLinePropType) => {
  const { width = '100%', weight = '50', bgColor } = props;

  return `
    height: 1px;
    width: ${width};
    background-color: ${bgColor || '#141D49'}${weight};
  `;
});
