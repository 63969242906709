import { Menu, MenuItem } from '@mui/material';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Avatar, OrganizationItem } from 'Components';
import styles from '../../layout.module.css';
import { Modal } from 'antd';
import { getRequest, refreshToken } from 'api';
import { useAppContext } from 'Context/AppContext';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { removeStorage } from 'utils';

const menu_item_sx = {
  minWidth: '200px',
  fontFamily: 'Quicksand',
  fontWeight: '600',
  borderRadius: '7px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  color: '#141D49',

  '&:hover': {
    backgroundColor: '#dddddd',
  },
};

export const AccountDropdown = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {
    orgList,
    currentOrg,
    changeOrganization,
    disconnectSocket,
    currentOrgInfo,
  } = useAppContext();

  const SelectOrganization = (orgId: string, memberId: string) => {
    localStorage.setItem('organizationId', orgId);
    changeOrganization(orgId, memberId);
    navigate('/');
  };

  const handleClick = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const Logout = async () => {
    handleCloseMenu();
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận Đăng xuất?',
      async onOk() {
        await refreshToken();
        disconnectSocket();
        getRequest('/auth/logout');
        removeStorage();
        navigate('/login');
      },
      onCancel() {},
    });
  };

  return (
    <div>
      <Avatar
        id='account-avatar'
        className={styles.account_avatar}
        src={currentOrgInfo?.memberAvatar}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />
      <Menu
        id='account-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        autoFocus={false}
        MenuListProps={{
          'aria-labelledby': 'account-avatar',
        }}
        PaperProps={{
          sx: {
            right: 10,
            left: 'auto !important',
            borderRadius: '10px',
            padding: '5px',
            marginTop: '10px',
            boxShadow: 'none',
            border: 'solid 1px #cacaca',
          },
        }}
      >
        {orgList?.map((org, index) => (
          <OrganizationItem
            {...org}
            isSelected={org.id === currentOrg}
            key={index}
            selectOrg={() => SelectOrganization(org.id, org.memberId)}
          />
        ))}
        <MenuItem
          onClick={handleCloseMenu}
          sx={{
            padding: 0,
            ...menu_item_sx,
          }}
        >
          <Link className={styles.menu_item} to='/my-account'>
            Tài khoản của tôi
            <Person2OutlinedIcon sx={{ marginLeft: 1 }} />
          </Link>
        </MenuItem>
        {/* <div className={styles.line} /> */}
        <MenuItem sx={menu_item_sx} onClick={Logout}>
          Đăng xuất
          <ArrowForwardOutlinedIcon sx={{ marginLeft: 1 }} />
        </MenuItem>
      </Menu>
    </div>
  );
};
