export enum FileStatus {
  USE = 1,
  UNUSE = 0,
}

export enum OpenFileType {
  DOWNLOAD = 1,
  OPEN = 2,
}

export enum AttachmentType {
  STREAM = 'stream',
  COMMENT = 'comment',
}

export interface AttachedFileInfo {
  fileId: string;
  originalName: string;
  uuidName: string;
  path: string;
  status: number;
}

export interface AttachmentItemPropType {
  type: AttachmentType;
  attachedFile: AttachedFileInfo;
  updatePermission: boolean;
  setAttachedFileList: React.Dispatch<
    React.SetStateAction<AttachedFileInfo[] | undefined>
  >;
}
