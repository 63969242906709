import { Link } from 'react-router-dom';
import styles from './layout.module.css';

interface NavigationTreeProps {
  navKey?: string;
  subNavKey?: string;
  subSubNavKey?: string;
}

const navList = {
  home: { name: 'Trang chủ', path: '/' },
  account: { name: 'Tài khoản của tôi', path: '/my-account' },
};

const subNavList = {
  proposal_list: { name: 'Danh sách đề xuất', path: '/proposal-list' },
  request_list: { name: 'Danh sách đề nghị', path: '/request-list' },
  create_proposal: { name: 'Tạo đề xuất', path: '/create-proposal' },
  create_request: { name: 'Tạo đề nghị', path: '/create-request' },
  member_list: { name: 'Danh sách nhân sự', path: '/member-list' },
};

const subSubNavList = {
  proposal_detail: { name: 'Chi tiết đề xuất', path: '' },
  request_detail: { name: 'Chi tiết đề nghị', path: '' },
  proposal_history: { name: 'Lịch sử đề xuất', path: '' },
  request_history: { name: 'Lịch sử đề nghị', path: '' },
};

export function NavigationTree(props: NavigationTreeProps) {
  const { navKey, subNavKey, subSubNavKey } = props;
  return (
    <div className={styles.navigation_tree}>
      <Link
        className={`${styles.link} ${subNavKey ? '' : styles.current_nav}`}
        to={navList[navKey as keyof typeof navList].path}
      >
        {navList[navKey as keyof typeof navList].name}
      </Link>
      &nbsp;&nbsp;
      {subNavKey && (
        <>
          <span>{'>>'}</span>
          &nbsp;&nbsp;
          <Link
            className={`${styles.link} ${
              subSubNavKey ? '' : styles.current_nav
            }`}
            to={subNavList[subNavKey as keyof typeof subNavList].path}
          >
            {subNavList[subNavKey as keyof typeof subNavList].name}
          </Link>
          &nbsp;&nbsp;
        </>
      )}
      {subSubNavKey && (
        <>
          <span>{'>>'}</span>
          &nbsp;&nbsp;
          <Link
            className={`${styles.link} ${styles.current_nav}`}
            to={subSubNavList[subSubNavKey as keyof typeof subSubNavList].path}
          >
            {subSubNavList[subSubNavKey as keyof typeof subSubNavList].name}
          </Link>
        </>
      )}
    </div>
  );
}
