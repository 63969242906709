import { ImgHTMLAttributes } from 'react';

interface ImagePropType extends ImgHTMLAttributes<HTMLImageElement> {
  defaultSrc: string;
}

export const Image = (props: ImagePropType) => {
  const { defaultSrc, src, ...rest } = props;
  return (
    <img
      src={src ? src : defaultSrc}
      alt=''
      onError={(e) => {
        (e.target as HTMLImageElement).src = defaultSrc;
      }}
      {...rest}
    />
  );
};
