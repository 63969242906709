import { Modal } from 'antd';
import { ResponseDataType } from 'api';
import { getRequest } from 'api';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { removeStorage } from 'utils';

export const ConfirmSignup = () => {
  const { customerId, key } = useParams();
  const navigate = useNavigate();

  const renderErrorMessage = (message: string | string[]) => {
    if (typeof message === 'object') {
      return (
        <>
          {message.map((msg: string) => (
            <p>{msg}</p>
          ))}
        </>
      );
    } else return message;
  };

  useEffect(() => {
    const ConfirmEmail = async () => {
      const data: ResponseDataType<{}> = await getRequest(
        `/auth/verify-email?customerId=${customerId}&key=${key}`
      );
      if (data?.code) {
        Modal.error({
          style: { fontFamily: 'Quicksand' },
          title: 'Xin lỗi!',
          content: renderErrorMessage(data.message),
          onOk() {
            navigate('/');
          },
        });
      } else
        Modal.success({
          style: { fontFamily: 'Quicksand' },
          title: 'Thành công!',
          content: 'Kích hoạt tài khoản thành công',
          onOk() {
            removeStorage();
            navigate('/login');
          },
        });
    };

    ConfirmEmail();
  }, [customerId, key, navigate]);

  return <></>;
};
