import styles from './authen.module.css';

export default function TermsPopup({ closeTerms }: { closeTerms: Function }) {
  const HandleBlur = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      closeTerms();
    }
  };

  return (
    <div className={styles.terms_popup_wrapper} onClick={HandleBlur}>
      <div className={styles.terms_box}>
        <div className={styles.close_button} onClick={() => closeTerms()} />
        <div className={styles.terms_title}>Điều khoản sử dụng</div>
        <div className={styles.terms_header}>Privacy Policy</div>
        <div className={styles.terms_header}>Last updated: May 12, 2024</div>
        <div className={styles.terms_text}>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </div>
        <div className={styles.terms_text}>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy has been
          created with the help of the&nbsp;
          <a
            style={{ textDecoration: 'underline' }}
            href='https://www.termsfeed.com/privacy-policy-generator/'
            target='_blank'
            rel='noreferrer'
          >
            Privacy Policy Generator
          </a>
          .
        </div>
        <div className={styles.terms_text}>Interpretation and Definitions</div>
        <div className={styles.terms_text}>Interpretation</div>
        <div className={styles.terms_text}>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </div>
        <div className={styles.terms_text}>Definitions</div>
        <div className={styles.terms_header}>
          For the purposes of this Privacy Policy:
        </div>
        <ul>
          <li className={styles.terms_text}>
            Account means a unique account created for You to access our Service
            or parts of our Service.
          </li>
          <li className={styles.terms_text}>
            Affiliate means an entity that controls, is controlled by or is
            under common control with a party, where "control" means ownership
            of 50% or more of the shares, equity interest or other securities
            entitled to vote for election of directors or other managing
            authority.
          </li>
          <li className={styles.terms_text}>
            Company (referred to as either "the Company", "We", "Us" or "Our" in
            this Agreement) refers to Pip, 91 Trần Đại Nghĩa, Hai Bà Trưng, Hà
            Nội.
          </li>
          <li className={styles.terms_text}>
            Cookies are small files that are placed on Your computer, mobile
            device or any other device by a website, containing the details of
            Your browsing history on that website among its many uses.
          </li>
          <li className={styles.terms_text}>Country refers to: Vietnam.</li>
          <li className={styles.terms_text}>
            Device means any device that can access the Service such as a
            computer, a cellphone or a digital tablet.
          </li>
          <li className={styles.terms_text}>
            Personal Data is any information that relates to an identified or
            identifiable individual.
          </li>
          <li className={styles.terms_text}>Service refers to the Website.</li>
          <li className={styles.terms_text}>
            Service Provider means any natural or legal person who processes the
            data on behalf of the Company. It refers to third-party companies or
            individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services
            related to the Service or to assist the Company in analyzing how the
            Service is used.
          </li>
          <li className={styles.terms_text}>
            Usage Data refers to data collected automatically, either generated
            by the use of the Service.
          </li>
        </ul>
      </div>
    </div>
  );
}
