import { Image } from 'antd';
import { AttachmentItem } from './AttachmentItem';
import { AttachedFileInfo, AttachmentType } from './type';
import { checkIsImage } from './utils';
import { ImageAttachmentItem } from './ImageAttachmentItem';
import { useState } from 'react';

interface AttachedFileListPropType {
  attachedFileList: AttachedFileInfo[];
  type: AttachmentType;
  updatePermisstion: boolean;
  setAttachedFileList: React.Dispatch<
    React.SetStateAction<AttachedFileInfo[] | undefined>
  >;
}

export const AttachedFileList = (props: AttachedFileListPropType) => {
  const { attachedFileList, type, updatePermisstion, setAttachedFileList } =
    props;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [previewImageVisible, setPreviewImageVisible] = useState(false);

  return (
    <>
      {attachedFileList
        .filter((file) => !checkIsImage(file.uuidName, file.status))
        .map((attachedFile, index) => (
          <AttachmentItem
            key={index}
            type={type}
            attachedFile={attachedFile}
            updatePermission={updatePermisstion}
            setAttachedFileList={setAttachedFileList}
          />
        ))}
      <div
        style={{
          marginTop: 10,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 150px)',
          gap: 5,
        }}
      >
        <Image.PreviewGroup
          preview={{
            onVisibleChange: (visible) => {
              if (isOpenModal) {
                setPreviewImageVisible(false);
                return;
              }
              setPreviewImageVisible(visible);
            },
            visible: previewImageVisible,
          }}
        >
          {attachedFileList
            .filter((file) => checkIsImage(file.uuidName, file.status))
            .map((attachedFile, index) => (
              <ImageAttachmentItem
                key={index}
                type={type}
                attachedFile={attachedFile}
                updatePermission={updatePermisstion}
                setAttachedFileList={setAttachedFileList}
                setIsOpenModal={setIsOpenModal}
              />
            ))}
        </Image.PreviewGroup>
      </div>
    </>
  );
};
