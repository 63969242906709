import styles from '../../../stream.module.css';
import { MemberFullInfo } from '../MemberInfo';
import { CommentItem } from './CommentItem';
import { useEffect, useRef, useState } from 'react';
import { ResponseDataType, uploadFileRequest } from 'api';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { useAppContext } from 'Context/AppContext';
import { useStreamDetailInfo } from '../../Context/StreamDetailContext';
import { TextInputBox } from 'Components';
import { Row } from 'antd';
import { AttachFile, DeleteOutline } from '@mui/icons-material';
import { AttachedFileInfo, SelectedFileItem } from '../AttachedFile';

interface MemberInfoDataType {
  customerId?: string;
  name: string;
  avatar: string;
  role: number;
  departmentCode: string;
  departmentName: string;
  isOldInfo: boolean;
}

export interface CommentInfoDataType {
  sender: MemberInfoDataType;
  content: string;
  attachments: AttachedFileInfo[];
}

export const CommentBox = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { onEvent, currentOrg } = useAppContext();
  const [commentText, setCommentText] = useState<string>();
  const { commentList, setCommentList, selectedStreamId, myInfo } =
    useStreamDetailInfo();
  const [loadingSendComment, setLoadingSendComment] = useState<boolean>(false);
  const [selectedFileList, setSelectedFileList] = useState<File[]>([]);
  const customerId = localStorage.getItem('customerId') || '';

  useEffect(() => {
    onEvent(
      'new-comment',
      (res: { streamId: string; newComment: CommentInfoDataType }) => {
        if (selectedStreamId === res.streamId) {
          setCommentList((prev) => [...prev, res.newComment]);
        }
      }
    );
  }, [onEvent, selectedStreamId, setCommentList]);

  const sendComment = async () => {
    if ((!commentText || commentText.trim() === '') && !selectedFileList.length)
      return;

    setLoadingSendComment(true);
    const formData = new FormData();
    selectedFileList.forEach((file) => formData.append('files', file));
    formData.append('content', (commentText || '').trim());
    formData.append('streamId', selectedStreamId);

    const response: ResponseDataType<string> = await uploadFileRequest(
      `/comment/send-comment?orgId=${currentOrg}`,
      formData
    );

    if (response.code) {
      toast.error(response.message);
    } else {
      toast.success('Gửi comment thành công');
      setCommentText('');
      setSelectedFileList([]);
    }
    setLoadingSendComment(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList: File[] = Array.prototype.slice.call(event.target.files);
    setSelectedFileList((prev) => [...prev, ...fileList]);
  };

  const handleRemoveSelectedFile = (index: number) => {
    setSelectedFileList((prev) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ]);
  };

  return (
    <div style={{ marginTop: 50 }}>
      <div className={styles.content_label}>Thảo luận</div>
      {myInfo ? (
        <>
          <Row justify='space-between' style={{ marginBottom: 5 }}>
            <MemberFullInfo {...myInfo} />
            <Row
              align='middle'
              style={{ marginLeft: 25, fontWeight: 700, cursor: 'pointer' }}
              onClick={() => fileInputRef.current?.click()}
            >
              <AttachFile />
              <div>Thêm file</div>
            </Row>
            <input
              ref={fileInputRef}
              type='file'
              multiple
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </Row>
          {selectedFileList.map((file, index) => (
            <SelectedFileItem
              key={index}
              align='middle'
              justify='space-between'
              style={{ maxWidth: 'unset' }}
            >
              <div className='selected-file-name'>{file.name}</div>
              <div onClick={() => handleRemoveSelectedFile(index)}>
                <DeleteOutline
                  sx={{ color: '#D04339', cursor: 'pointer', fontSize: 16 }}
                />
              </div>
            </SelectedFileItem>
          ))}
          <TextInputBox
            loading={loadingSendComment}
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            handleSend={sendComment}
          />
          <div>
            {commentList.length < 10 ? '0' : ''}
            {commentList.length} thảo luận
          </div>
          {commentList.map((comment, index) => (
            <CommentItem key={index} {...comment} customerId={customerId} />
          ))}
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress
            size={50}
            sx={{ color: '#141d49', marginTop: 10 }}
          />
        </div>
      )}
    </div>
  );
};
