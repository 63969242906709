import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { EyeOutlined, MailOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import styles from './authen.module.css';
import { ResponseDataType, postRequest } from 'api';
import TermsPopup from './TermsPopup';

interface LoginDataType {
  accessToken?: string;
  refreshToken?: string;
  customerId?: string;
}

export const LoginPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [openTerms, setOpenTerms] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('email')) navigate('/');
  });

  const Login = async (value: { email: string; password: string }) => {
    try {
      const response: ResponseDataType<LoginDataType> = await postRequest(
        '/auth/login',
        {
          email: value.email,
          password: value.password,
        }
      );

      if (response.code) {
        toast.error(response.message);
      } else if (response.data?.accessToken && response.data?.refreshToken) {
        localStorage.setItem('email', value.email);
        localStorage.setItem(
          'accessToken',
          response.data.accessToken as string
        );
        localStorage.setItem(
          'refreshToken',
          response.data.refreshToken as string
        );
        localStorage.setItem('customerId', response.data.customerId as string);
        localStorage.setItem('email', value.email);
        window.location.href = '/';
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.login_page}>
      <div className={styles.authen_wrapper}>
        <img className={styles.logo} src='/Images/logo.png' alt='' />
        <div className={styles.name}>Adotsystem</div>
        <Form form={form} onFinish={(value) => Login(value)}>
          <div className={`${styles.form} authen-form`}>
            <Form.Item
              name='email'
              rules={[
                { required: true, message: 'Cần nhập email!' },
                { type: 'email', message: 'Cần nhập email đúng định dạng!' },
              ]}
              style={{ margin: 0 }}
            >
              <Input
                prefix={<MailOutlined className={styles.icon} />}
                placeholder='Email'
                className={styles.form_input}
              />
            </Form.Item>
            <div className={styles.line} />
            <Form.Item
              name='password'
              rules={[{ required: true, message: 'Cần nhập mật khẩu!' }]}
              style={{ margin: 0 }}
            >
              <Input.Password
                prefix={<EyeOutlined className={styles.icon} />}
                placeholder='Mật khẩu'
                className={styles.form_input}
              />
            </Form.Item>
          </div>
          <Button
            type='primary'
            className={styles.authen_button}
            htmlType='submit'
          >
            Đăng nhập
          </Button>
        </Form>
        <div className={`${styles.text} ${styles.agree_terms}`}>
          Khi tiếp tục, bạn được coi là đã đồng ý với
        </div>
        <div
          onClick={() => setOpenTerms(true)}
          className={`${styles.text} ${styles.open_terms_btn}`}
        >
          Điều khoản sử dụng
        </div>
        <div className={`${styles.text} ${styles.signup_link}`}>
          Chưa có tài khoản?&nbsp;
          <Link to='/sign-up'>Đăng ký ngay</Link>
        </div>
      </div>
      {openTerms && <TermsPopup closeTerms={() => setOpenTerms(false)} />}
    </div>
  );
};
