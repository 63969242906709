import { ActionPropType, StreamActionPropType } from '..';
import { updateApproveTaskValue } from './action';
import {
  ButtonBox,
  ButtonGreen,
  ButtonRed,
  DisableButtonGreenWrapper,
  DisableButtonRedWrapper,
  Space,
} from './style';
import { useAppContext } from 'Context/AppContext';

const ApproveDenyAction = (props: ActionPropType) => {
  const { streamId } = props;
  const { currentOrg } = useAppContext();

  return (
    <ButtonBox>
      <ButtonGreen
        onClick={() =>
          updateApproveTaskValue({
            value: 1,
            streamId,
            endStream: false,
            title: 'Xác nhận duyệt',
            currentOrg,
          })
        }
      >
        Duyệt
      </ButtonGreen>
      <Space />
      <ButtonRed
        onClick={() =>
          updateApproveTaskValue({
            value: 0,
            streamId,
            endStream: true,
            title: 'Xác nhận từ chối',
            currentOrg,
          })
        }
      >
        Từ chối
      </ButtonRed>
    </ButtonBox>
  );
};

export const ApproveTaskAction = (props: StreamActionPropType) => {
  const { isEnd, canUpdate, optionValue, ...rest } = props;

  if (optionValue === 1) {
    return <DisableButtonGreenWrapper label='Đã duyệt' />;
  }

  if (optionValue === 0) {
    return <DisableButtonRedWrapper label='Đã từ chối' />;
  }

  if (!canUpdate) {
    return <DisableButtonGreenWrapper label='Chờ duyệt' />;
  }

  return <ApproveDenyAction {...rest} />;
};

const ConfirmAction = (props: ActionPropType) => {
  const { streamId } = props;
  const { currentOrg } = useAppContext();

  return (
    <ButtonBox>
      <ButtonGreen
        onClick={() =>
          updateApproveTaskValue({
            value: 1,
            streamId,
            endStream: false,
            title: 'Xác nhận duyệt?',
            currentOrg,
          })
        }
      >
        Xác nhận
      </ButtonGreen>
    </ButtonBox>
  );
};

export const ConfirmTaskAction = (props: StreamActionPropType) => {
  const { isEnd, canUpdate, optionValue, ...rest } = props;

  if (optionValue === 1) {
    return <DisableButtonGreenWrapper label='Đã duyệt' />;
  }

  if (!canUpdate) {
    return <DisableButtonGreenWrapper label='Chờ duyệt' />;
  }

  return <ConfirmAction {...rest} />;
};
