import axios from 'axios';
import { removeStorage } from 'utils';

export interface ResponseDataType<T> {
  code: number;
  message: string | string[];
  data?: T;
}

export async function uploadFileRequest(
  url: string,
  formData: FormData
): Promise<any> {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + '/api/web' + url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.status === 401) {
      if (await refreshToken()) return;
      return await uploadFileRequest(url, formData);
    }
    if (error?.response?.status === 413) {
      return {
        code: -99,
        message: 'Dung lượng file vượt quá 20MB',
      };
    }
    return error?.response?.data;
  }
}

export async function postRequest(url: string, body: any): Promise<any> {
  try {
    let response = await axios.post(
      process.env.REACT_APP_BASE_URL + '/api/web' + url,
      body,
      generateRequestHeader()
    );
    return response.data;
  } catch (error: any) {
    // console.log(error);
    if (error?.response?.status === 401) {
      if (await refreshToken()) return;
      return await postRequest(url, body);
    }
    return error?.response?.data;
  }
}

export async function getRequest(url: string): Promise<any> {
  try {
    let response = await axios.get(
      process.env.REACT_APP_BASE_URL + '/api/web' + url,
      generateRequestHeader()
    );
    return response.data;
  } catch (error: any) {
    // console.log(error);
    if (error?.response?.status === 401) {
      if (await refreshToken()) return;
      return await getRequest(url);
    }
    return error?.response?.data;
  }
}

export async function deleteRequest(url: string): Promise<any> {
  try {
    let response = await axios.delete(
      process.env.REACT_APP_BASE_URL + '/api/web' + url,
      generateRequestHeader()
    );
    return response.data;
  } catch (error: any) {
    // console.log(error);
    if (error?.response?.status === 401) {
      if (await refreshToken()) return;
      return await deleteRequest(url);
    }
    return error?.response?.data;
  }
}

export function generateRequestHeader() {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  };
}

export async function refreshToken() {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + '/api/web/auth/refresh-token',
      {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    localStorage.setItem('accessToken', await response.data.data.accessToken);
    localStorage.setItem('refreshToken', await response.data.data.refreshToken);
  } catch (error) {
    // console.log('refresh err:', error);
    removeStorage();
    window.location.href = '/login';
    return true;
  }
}

// export async function postRequestAI(url: string, body: any) {
//   try {
//     let response = await axios.post(
//       process.env.REACT_APP_SERVER_AI_URL + '/api' + url,
//       body,
//       {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       },
//     );

//     return response.data;
//   } catch (error) {
//     console.log(error);
//     return error;
//   }
// }
