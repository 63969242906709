import { AttachFile, DeleteOutline } from '@mui/icons-material';
import { Button, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useStreamDetailInfo } from '../../Context/StreamDetailContext';
import { ResponseDataType, uploadFileRequest } from 'api';
import { useAppContext } from 'Context/AppContext';
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { UploadOutlined } from '@ant-design/icons';
import { AttachmentType } from './type';
import { AttachedFileList } from './AttachedFileList';

export const AttachedFile = ({ isAssignee }: { isAssignee: boolean }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFileList, setSelectedFileList] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const { selectedStreamId, attachedFileList, setAttachedFileList } =
    useStreamDetailInfo();
  const { currentOrg, onEvent } = useAppContext();

  useEffect(() => {
    onEvent('add-attachment', (attachmentInfo) => {
      const { streamId, uploadedFileList } = attachmentInfo || {};
      if (streamId === selectedStreamId) {
        setAttachedFileList((prev) => [...uploadedFileList, ...(prev || [])]);
      }
    });
  }, [onEvent, selectedStreamId, setAttachedFileList]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList: File[] = Array.prototype.slice.call(event.target.files);
    const fileValidatedList = fileList.filter((file) => {
      if (file.size < 20000000) return true;

      toast.error(`File ${file.name} có dung lượng lớn hơn 20MB`);
      return false;
    });
    setSelectedFileList((prev) => [...prev, ...fileValidatedList]);
  };

  const handleRemoveSelectedFile = (index: number) => {
    setSelectedFileList((prev) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ]);
  };

  const handleUploadFile = async () => {
    setUploading(true);
    const formData = new FormData();
    selectedFileList.forEach((file) => formData.append('files', file));

    const response: ResponseDataType<string> = await uploadFileRequest(
      `/stream/upload-file?orgId=${currentOrg}&streamId=${selectedStreamId}`,
      formData
    );

    if (response.code) {
      toast.error(response.message);
    } else {
      toast.success('Tải lên thành công');
      setSelectedFileList([]);
    }
    setUploading(false);
  };

  return (
    <>
      <Row align='middle' style={{ color: '#141D49', marginTop: 30 }}>
        <div>Tài liệu đính kèm</div>
        {isAssignee && (
          <Row
            align='middle'
            style={{ marginLeft: 25, fontWeight: 700, cursor: 'pointer' }}
            onClick={() => fileInputRef.current?.click()}
          >
            <AttachFile />
            <div>Đính kèm file</div>
          </Row>
        )}
      </Row>
      <input
        ref={fileInputRef}
        type='file'
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      {selectedFileList.map((file, index) => (
        <SelectedFileItem key={index} align='middle' justify='space-between'>
          <div className='selected-file-name'>{file.name}</div>
          <div onClick={() => handleRemoveSelectedFile(index)}>
            <DeleteOutline
              sx={{ color: '#D04339', cursor: 'pointer', fontSize: 16 }}
            />
          </div>
        </SelectedFileItem>
      ))}
      {selectedFileList.length ? (
        <Row style={{ marginTop: 10 }}>
          <Button
            icon={<UploadOutlined />}
            onClick={handleUploadFile}
            disabled={uploading}
            loading={uploading}
          >
            Tải lên
          </Button>
        </Row>
      ) : (
        <></>
      )}
      {attachedFileList ? (
        <AttachedFileList
          attachedFileList={attachedFileList}
          type={AttachmentType.STREAM}
          updatePermisstion={isAssignee}
          setAttachedFileList={setAttachedFileList}
        />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress
            size={50}
            sx={{ color: '#141d49', marginTop: 10 }}
          />
        </div>
      )}
    </>
  );
};

export const SelectedFileItem = styled(Row)`
  padding: 5px 7px;
  border-radius: 5px;
  max-width: 350px;

  &:hover {
    background-color: #eee;
  }

  .selected-file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    width: calc(100% - 20px);
  }
`;
