import styled from '@emotion/styled';
import { Apps } from '@mui/icons-material';
import { Menu } from '@mui/material';
import { Image } from 'Components';
import { Row } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const serviceList = [
  {
    icon: '/Images/hr-service-icon.png',
    name: 'HR Analyst',
    url: '/member-list',
  },
  {
    icon: '/Images/docs-service-icon.png',
    name: 'Tài liệu',
    url: '',
  },
];

export const ServiceDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <div
        id='service-dropdown'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Apps sx={{ cursor: 'pointer', fontSize: '30px' }} />
      </div>
      <Menu
        id='account-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        autoFocus={false}
        MenuListProps={{
          'aria-labelledby': 'service-dropdown',
        }}
        PaperProps={{
          sx: {
            right: 50,
            left: 'auto !important',
            borderRadius: '10px',
            padding: 3,
            marginTop: '10px',
            boxShadow: 'none',
            border: 'solid 1px #cacaca',
          },
        }}
      >
        <Row>
          {serviceList.map((service, index) => (
            <ServiceItemWrapper
              key={index}
              onClick={handleCloseMenu}
              to={service.url}
              style={{ marginLeft: index ? 30 : 0 }}
            >
              <Image
                style={{ width: 50, height: 50 }}
                defaultSrc={service.icon}
              />
              <div style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>
                {service.name}
              </div>
            </ServiceItemWrapper>
          ))}
        </Row>
      </Menu>
    </div>
  );
};

const ServiceItemWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #141d49;

  &:hover {
    color: #646c93;
  }
`;
