import styled from '@emotion/styled';
import { Send } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { Input } from 'antd';

interface TextInputBoxPropType {
  loading?: boolean;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  handleSend?: React.MouseEventHandler<SVGSVGElement>;
}

export const TextInputBox = (props: TextInputBoxPropType) => {
  const { loading, value, onChange, handleSend } = props;
  return (
    <div style={{ position: 'relative', margin: '20px 0' }}>
      <TextInput autoSize value={value} onChange={onChange} />
      {loading ? (
        <CircularProgressIcon size={22} sx={{ color: '#141d49' }} />
      ) : (
        <SendIcon onClick={handleSend} />
      )}
    </div>
  );
};

const TextInput = styled(Input.TextArea)`
  padding-right: 45px;
  max-height: 200px;
  min-height: 45px !important;
`;

const iconStyle = `
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 25px;
`;

const SendIcon = styled(Send)`
  ${iconStyle}
`;

const CircularProgressIcon = styled(CircularProgress)`
  ${iconStyle}
`;
