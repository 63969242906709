import styled from '@emotion/styled';
import { Col } from 'antd';
import styles from '../../../stream.module.css';
import { TextInputBox } from 'Components';
import { useEffect, useRef, useState } from 'react';
import { ResponseDataType, getRequest, postRequest } from 'api';
import { useAppContext } from 'Context/AppContext';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { ChatItem } from './ChatItem';

export interface SenderInfo {
  name: string;
  avatar: string;
  memberId: string;
  customerId: string;
}

interface StreamChatMessageInfo {
  content: string;
  createAt: string;
  sender: SenderInfo;
}

interface ChatInStreamPropType {
  rootId: string;
}

export const ChatInStream = ({ rootId }: ChatInStreamPropType) => {
  const ref = useRef<HTMLDivElement>(null);
  const [msgText, setMsgText] = useState<string>();
  const [msgList, setMsgList] = useState<StreamChatMessageInfo[]>();
  const { currentOrg, onEvent, setSeenChatNotiRootId } = useAppContext();
  const [loadingSendMsg, setLoadingSendMsg] = useState<boolean>(false);

  const sendComment = async () => {
    if (!msgText || msgText.trim() === '') return;

    setLoadingSendMsg(true);
    const response: ResponseDataType<string> = await postRequest(
      '/chat/send-stream-chat',
      {
        organizationId: currentOrg,
        rootId,
        content: msgText.trim(),
      }
    );

    if (response.code) {
      toast.error(response.message);
    } else {
      setMsgText('');
    }
    setLoadingSendMsg(false);
  };

  useEffect(() => {
    const chatWrapperElement = ref.current;
    if (!chatWrapperElement) return;

    const setChatWrapperHeight = () => {
      const offset = 100 - window.scrollY;
      if (offset < 0) return;

      chatWrapperElement.style.height = `calc(100vh - ${70 + offset}px)`;
    };

    window.addEventListener('scroll', setChatWrapperHeight);

    return () => {
      window.removeEventListener('scroll', setChatWrapperHeight);
    };
  }, []);

  useEffect(() => {
    getRequest(`/notification/see-notification/${rootId}?type=chat`);

    setSeenChatNotiRootId(rootId);

    return () => {
      setSeenChatNotiRootId(undefined);
    };
  }, [rootId, setSeenChatNotiRootId]);

  useEffect(() => {
    const getStreamChatMsg = async () => {
      const response: ResponseDataType<StreamChatMessageInfo[]> =
        await getRequest(
          `/chat/stream-chat-msg?orgId=${currentOrg}&rootId=${rootId}`
        );

      if (response.code) {
        toast.error(response.message);
      } else {
        setMsgList(response.data);
      }
    };

    getStreamChatMsg();
  }, [currentOrg, rootId]);

  useEffect(() => {
    onEvent(
      'new-stream-chat',
      (res: StreamChatMessageInfo & { rootId: string }) => {
        const { rootId: newChatStreamRootId, ...rest } = res;
        if (rootId === newChatStreamRootId) {
          setMsgList((prev) => [rest, ...(prev || [])]);
          getRequest(`/notification/see-notification/${rootId}?type=chat`);
        }
      }
    );
  }, [onEvent, rootId, setSeenChatNotiRootId]);

  // time2 is after time1
  const checkTimeDiff = (time1: string, time2: string) => {
    if (!time1 || !time2) return false;

    const timestamp1 = new Date(time1).getTime();
    const timestamp2 = new Date(time2).getTime();

    return timestamp2 - timestamp1 < 900000; // check time diff between message and previous message is less than 15 minutes
  };

  return (
    <ChatWrapper span={12} ref={ref} id='chat-in-stream-wrapper'>
      <div className={styles.content_label}>Trao đổi</div>
      {msgList ? (
        <ChatContentBox>
          <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
            {msgList.map((msg, index) => (
              <div key={index}>
                {checkTimeDiff(msgList[index + 1]?.createAt, msg.createAt) || (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      margin: '20px 0',
                    }}
                  >
                    {moment(msg.createAt).format('HH:mm - DD ')}Tháng
                    {moment(msg.createAt).format(' MM, YYYY')}
                  </div>
                )}
                <ChatItem
                  content={msg.content}
                  sender={msg.sender}
                  isNearingNext={
                    msg.sender.memberId ===
                      msgList[index - 1]?.sender.memberId &&
                    checkTimeDiff(msg.createAt, msgList[index - 1]?.createAt)
                  }
                  isNearingPrevious={
                    msg.sender.memberId ===
                      msgList[index + 1]?.sender.memberId &&
                    checkTimeDiff(msgList[index + 1]?.createAt, msg.createAt)
                  }
                />
              </div>
            ))}
          </div>
        </ChatContentBox>
      ) : (
        <div style={{ display: 'flex', flexGrow: 1 }}>
          <CircularProgress
            size={50}
            sx={{ color: '#141d49', margin: 'auto' }}
          />
        </div>
      )}
      <TextInputBox
        loading={loadingSendMsg}
        value={msgText}
        onChange={(e) => setMsgText(e.target.value)}
        handleSend={sendComment}
      />
    </ChatWrapper>
  );
};

const ChatWrapper = styled(Col)`
  position: sticky;
  top: 50px;
  height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;
`;

const ChatContentBox = styled.div`
  flex-grow: 1;
  overflow: auto;
`;
