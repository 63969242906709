import {
  LoginPage,
  SignupPage,
  NotFound,
  ConfirmSignup,
  Account,
  LoginNewAccount,
  ProcessingStreamList,
  StreamDetail,
  CreateStream,
  OrgHome,
  HistoryStreamList,
  MemberList,
} from 'Pages';

export const PUBLIC_ROUTER = [
  {
    key: 'login',
    path: '/login',
    element: <LoginPage />,
  },
  {
    key: 'signup',
    path: '/sign-up',
    element: <SignupPage />,
  },
  {
    key: 'not-found',
    path: '*',
    element: <NotFound />,
  },
  {
    key: 'confirm',
    path: '/confirm/:customerId/:key',
    element: <ConfirmSignup />,
  },
  {
    key: 'login-new-acc',
    path: '/login-new-account',
    element: <LoginNewAccount />,
  },
];

export const PRIVATE_ROUTER = [
  {
    key: 'home',
    path: '/',
    navKey: 'home',
    element: <OrgHome />,
  },
  {
    key: 'my-account',
    path: '/my-account',
    navKey: 'account',
    element: <Account />,
  },
  {
    key: 'proposal-list',
    path: '/proposal-list',
    navKey: 'home',
    subNavKey: 'proposal_list',
    element: <ProcessingStreamList />,
  },
  {
    key: 'proposal-history',
    path: '/proposal-list/history',
    navKey: 'home',
    subNavKey: 'proposal_list',
    subSubNavKey: 'proposal_history',
    element: <HistoryStreamList />,
  },
  {
    key: 'request-list',
    path: '/request-list',
    navKey: 'home',
    subNavKey: 'request_list',
    element: <ProcessingStreamList />,
  },
  {
    key: 'request-history',
    path: '/request-list/history',
    navKey: 'home',
    subNavKey: 'request_list',
    subSubNavKey: 'request_history',
    element: <HistoryStreamList />,
  },
  {
    key: 'proposal-detail',
    path: '/proposal-detail/:streamId',
    navKey: 'home',
    subNavKey: 'proposal_list',
    subSubNavKey: 'proposal_detail',
    element: <StreamDetail />,
  },
  {
    key: 'request-detail',
    path: '/request-detail/:streamId',
    navKey: 'home',
    subNavKey: 'request_list',
    subSubNavKey: 'request_detail',
    element: <StreamDetail />,
  },
  {
    key: 'create-proposal',
    path: '/create-proposal',
    navKey: 'home',
    subNavKey: 'create_proposal',
    element: <CreateStream />,
  },
  {
    key: 'create-request',
    path: '/create-request',
    navKey: 'home',
    subNavKey: 'create_request',
    element: <CreateStream />,
  },
  {
    key: 'member-list',
    path: '/member-list',
    navKey: 'home',
    subNavKey: 'member_list',
    element: <MemberList />,
  },
];
