import { Button, Checkbox, Form, Input, Select } from 'antd';

import styles from '../stream.module.css';
import { ResponseDataType, getRequest, postRequest } from 'api';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppContext } from 'Context/AppContext';
import { ProposalType, RequestType } from 'constant/enum';

const enum CreateType {
  PROPOSAL = 0,
  REQUEST = 1,
}

const CREATE_INFO = {
  [CreateType.PROPOSAL]: {
    en: 'proposal',
    vi: 'đề xuất',
    streamTypeList: [
      {
        value: 1,
        label: 'Đề xuất chung',
        requestUrl: '/org-stream/create-general-proposal',
        type: ProposalType.GENERAL as number,
      },
    ],
  },
  [CreateType.REQUEST]: {
    en: 'request',
    vi: 'đề nghị',
    streamTypeList: [
      {
        value: 1,
        label: 'Đề nghị thanh toán',
        requestUrl: '/org-stream/create-payment-request',
        type: RequestType.PAYMENT,
      },
      {
        value: 2,
        label: 'Đề nghị tạm ứng',
        requestUrl: '/org-stream/create-payment-request',
        type: RequestType.ADVANCE,
      },
      {
        value: 3,
        label: 'Đề nghị hoàn ứng',
        requestUrl: '/org-stream/create-payment-request',
        type: RequestType.REIMBURSEMENT,
      },
    ],
  },
};

interface CreateInfoDataType {
  groupList: { id: string; name: string; typeCode: string }[];
}

interface OptionList {
  value: string;
  label: string;
  typeCode: string;
}

export const CreateStream = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentOrg } = useAppContext();
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [groupOptions, setGroupOptions] = useState<OptionList[]>([]);
  const createType = location.pathname.includes('create-proposal')
    ? CreateType.PROPOSAL
    : CreateType.REQUEST;

  useEffect(() => {
    const getCreateInfo = async () => {
      const res: ResponseDataType<CreateInfoDataType> = await getRequest(
        `/org-stream/${currentOrg}/get-create-info`
      );

      if (res.code) {
        toast.error(res.message);
      } else {
        const groupOptionList = res.data?.groupList.map((e) => ({
          value: e.id,
          label: e.name,
          typeCode: e.typeCode,
        }));
        setGroupOptions(groupOptionList || []);
      }
    };

    getCreateInfo();
  }, [currentOrg]);

  const handleCreateStream = async (value: {
    description: string;
    type: number;
    groupList: string[];
    content: string;
  }) => {
    setIsBtnLoading(true);
    const createInfo = CREATE_INFO[createType].streamTypeList.find(
      (e) => e.value === value.type
    );
    const response: ResponseDataType<string> = await postRequest(
      createInfo?.requestUrl as string,
      {
        organizationId: currentOrg,
        description: value.description,
        content: value.content,
        type: createInfo?.type,
        groupList: value.groupList || [],
        groupTypeCode: groupOptions?.find(
          (group) => group.value === value.groupList?.[0]
        )?.typeCode,
      }
    );

    if (response.code) {
      toast.error(response.message);
    } else {
      toast.success(`Tạo ${CREATE_INFO[createType].vi} thành công`);
      navigate(`/${CREATE_INFO[createType].en}-detail/${response.data}`);
    }
    setIsBtnLoading(false);
  };

  return (
    <div className={styles.create_stream_page}>
      <Form form={form} onFinish={handleCreateStream} style={{ width: 300 }}>
        <div className={styles.form_label}>
          Chọn loại {CREATE_INFO[createType].vi}
        </div>
        <Form.Item name='type' initialValue={1}>
          <Select
            options={CREATE_INFO[createType].streamTypeList}
            style={{ height: 40 }}
            labelRender={(label) => (
              <span style={{ padding: 5 }}>{label.label}</span>
            )}
          />
        </Form.Item>
        <div className={styles.form_label}>Tiêu đề</div>
        <Form.Item name='description'>
          <Input.TextArea style={{ height: 30 }} placeholder={`Tiêu đề `} />
        </Form.Item>
        <div className={styles.form_label}>
          Nội dung {CREATE_INFO[createType].vi}
        </div>
        <Form.Item
          name='content'
          rules={[
            {
              required: true,
              message: `Cần nhập nội dung ${CREATE_INFO[createType].vi}!`,
            },
          ]}
        >
          <Input.TextArea
            style={{ minHeight: 100 }}
            placeholder={`Nội dung ${CREATE_INFO[createType].vi}`}
          />
        </Form.Item>
        <Form.Item
          name='groupList'
          rules={[
            () => ({
              validator(_, value) {
                if (!value || value.length === 0) {
                  return Promise.reject('Cần chọn 1 group');
                }

                if (value.length > 1) {
                  return Promise.reject('Chỉ được chọn 1 group');
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Checkbox.Group options={groupOptions} />
        </Form.Item>
        <Button
          loading={isBtnLoading}
          type='primary'
          htmlType='submit'
          className={styles.form_button_submit}
        >
          Tạo {CREATE_INFO[createType].vi}
        </Button>
      </Form>
    </div>
  );
};
