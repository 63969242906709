import { MemberStatus } from 'constant/enum';

export const getMemberStatusText = (status: number) => {
  if (status === MemberStatus.ACTIVE) return 'Đang làm việc';

  if (status === MemberStatus.DEACTIVATE) return 'Đã nghỉ';

  if (status === MemberStatus.DELETED) return 'Đã xóa';

  return 'Loading';
};
