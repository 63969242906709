import { Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useState } from 'react';
import { menu_item_for_link_sx } from 'constant/styled';
import styled from '@emotion/styled';

export const CreateStreamButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        id='create-option'
        style={{
          borderRadius: '50%',
          position: 'fixed',
          left: '20px',
          bottom: '20px',
        }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <AddCircleIcon
          sx={{
            fontSize: '70px',
            cursor: 'pointer',
            color: '#141D49',

            '@media (max-width: 480px)': {
              fontSize: '50px',
            },
          }}
        />
      </div>
      <Menu
        // anchorPosition={}
        id='create-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        autoFocus={false}
        MenuListProps={{
          'aria-labelledby': 'create-option',
        }}
        PaperProps={{
          sx: {
            borderRadius: '10px',
            padding: '5px',
            marginTop: '-90px',
            border: 'solid 1px #cacaca',
            boxShadow: 'none',
            minWidth: '200px',
          },
        }}
      >
        <MenuItem sx={menu_item_for_link_sx}>
          <LinkInOptionMenu to='/create-proposal'>
            <AddIcon />
            &nbsp;Tạo đề xuất
          </LinkInOptionMenu>
        </MenuItem>
        <MenuItem sx={menu_item_for_link_sx}>
          <LinkInOptionMenu to='/create-request'>
            <AddIcon />
            &nbsp;Tạo đề nghị
          </LinkInOptionMenu>
        </MenuItem>
      </Menu>
    </>
  );
};

const LinkInOptionMenu = styled(Link)`
  width: 100%;
  height: 100%;
  padding: 6px 16px;
  font-weight: 700;
  color: #141d49 !important;
  display: flex;
  align-items: center;
  line-height: normal;
`;
