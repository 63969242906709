import { Checkbox, Form, Input, Row, Select } from 'antd';
import {
  DepartmentInfo,
  DepartmentRole,
  GroupInfo,
  MemberDetailInfoType,
} from '../type';
import { WorkOutline } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled';
import { Avatar } from 'Components';
import { getFullInfoInDepartment, getMemberStatusText } from 'utils';
import { MemberStatus } from 'constant/enum';

interface MemberDetailInfoPropType {
  memberInfo: MemberDetailInfoType;
  departmentList: DepartmentInfo[];
  groupList: GroupInfo[];
  isEditing: boolean;
}

export const MemberDetailInfo = (props: MemberDetailInfoPropType) => {
  const {
    memberInfo,
    departmentList,
    groupList: orgGroupList,
    isEditing,
  } = props;
  const {
    id,
    email,
    name,
    phone,
    avatar,
    department,
    departmentNamecode,
    role,
    groupList,
    status,
  } = memberInfo;

  if (id === '')
    return (
      <Row justify='center'>
        <CircularProgress size={50} sx={{ color: '#141d49' }} />
      </Row>
    );

  return (
    <div>
      <Row align='middle' style={{ marginBottom: 40 }}>
        <Avatar
          src={avatar}
          style={{
            height: 100,
            width: 100,
            borderRadius: '50%',
            marginRight: 20,
          }}
        />
        <div>
          {isEditing ? (
            <Form.Item
              name='name'
              rules={[{ required: true, message: 'Cần nhập tên!' }]}
            >
              <Input style={formItemFontStyle} />
            </Form.Item>
          ) : (
            <div style={{ fontSize: 24, fontWeight: 700 }}>{name}</div>
          )}
          <Row style={{ fontSize: 18, margin: '15px 0' }}>
            <div style={{ fontWeight: 700 }}>Email:</div>&nbsp;
            <div style={{ color: '#009be5', fontWeight: 600 }}>{email}</div>
          </Row>
          {isEditing ? (
            <Form.Item name='phone'>
              <Input style={formItemFontStyle} />
            </Form.Item>
          ) : (
            <Row style={{ fontSize: 18 }}>
              <div style={{ fontWeight: 700 }}>Phone:</div>&nbsp;
              <div>{phone || ''}</div>
            </Row>
          )}
        </div>
      </Row>
      <RowWrapper>
        <WorkIcon />
        <InfoFieldWrapper>
          <InfoFieldLabel>Vị trí công việc</InfoFieldLabel>
          {isEditing ? (
            <div style={{ width: '100%', maxWidth: 300 }}>
              <Form.Item name='department'>
                <Select
                  optionFilterProp='label'
                  showSearch={true}
                  style={{ marginBottom: 0 }}
                  allowClear
                  labelRender={(label) => (
                    <div style={formItemFontStyle}>{label.label}</div>
                  )}
                  options={departmentList.map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                  optionRender={(option) => (
                    <div style={formItemFontStyle}>{option.label}</div>
                  )}
                />
              </Form.Item>
              <Form.Item
                name='role'
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && !getFieldValue('department')) {
                        return Promise.reject('Chưa chọn phòng ban');
                      }

                      if (getFieldValue('department') && value === undefined) {
                        return Promise.reject(
                          'Cần chọn vai trò trong phòng ban'
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Select
                  style={{ marginBottom: 0 }}
                  allowClear
                  options={[
                    { label: 'Trường phòng', value: DepartmentRole.MANAGER },
                    { label: 'Nhân viên', value: DepartmentRole.STAFF },
                  ]}
                  labelRender={(label) => (
                    <div style={formItemFontStyle}>{label.label}</div>
                  )}
                  optionRender={(option) => (
                    <div style={formItemFontStyle}>{option.label}</div>
                  )}
                />
              </Form.Item>
            </div>
          ) : (
            <InfoFieldContent>
              {department
                ? getFullInfoInDepartment({
                    isOldInfo: status === MemberStatus.DEACTIVATE,
                    isManager: role === DepartmentRole.MANAGER,
                    department:
                      status === MemberStatus.DEACTIVATE
                        ? {
                            name: department,
                            namecode: departmentNamecode || '',
                          }
                        : departmentList.find((d) => d.id === department),
                  })
                : 'Chưa tham gia'}
            </InfoFieldContent>
          )}
        </InfoFieldWrapper>
      </RowWrapper>
      <RowWrapper>
        <WorkIcon />
        <InfoFieldWrapper>
          <InfoFieldLabel>Nơi làm việc</InfoFieldLabel>
          {isEditing ? (
            <Form.Item name='groups'>
              <Checkbox.Group
                style={formItemFontStyle}
                options={orgGroupList.map((g) => ({
                  value: g.id,
                  label: g.name,
                }))}
              />
            </Form.Item>
          ) : groupList && groupList.length ? (
            groupList.map((group, index) => (
              <InfoFieldContent key={index}>
                {status === MemberStatus.DEACTIVATE
                  ? group
                  : orgGroupList.find((g) => g.id === group)?.name}
              </InfoFieldContent>
            ))
          ) : (
            <InfoFieldContent>Chưa tham gia</InfoFieldContent>
          )}
        </InfoFieldWrapper>
      </RowWrapper>
      <RowWrapper>
        <WorkIcon />
        <InfoFieldWrapper>
          <InfoFieldLabel>Trạng thái</InfoFieldLabel>
          <InfoFieldContent>{getMemberStatusText(status)}</InfoFieldContent>
        </InfoFieldWrapper>
      </RowWrapper>
    </div>
  );
};

const formItemFontStyle = {
  fontWeight: 700,
  fontSize: 20,
};

const RowWrapper = styled(Row)`
  margin: 0 0 20px 30px;
`;

const WorkIcon = styled(WorkOutline)`
  margin-right: 55px;
  font-size: 35px;
  color: #009be5;
`;

const InfoFieldWrapper = styled.div`
  border-bottom: 1px solid #0005;
  flex-grow: 1;
  margin-right: 100px;
`;

const InfoFieldLabel = styled.div`
  font-size: 16px;
  color: #959595;
  margin-bottom: 20px;
`;

const InfoFieldContent = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
`;
