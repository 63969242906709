import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Grid, CircularProgress } from '@mui/material';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';

import styles from '../home.module.css';
import { ResponseDataType, getRequest } from 'api';
import { toast } from 'react-toastify';
import { orgPath } from 'constant/path';
import { circular_progress_sx } from 'constant/styled';
import { CreateStreamButton } from 'Components';
import { card_sx, grid_item_sx } from './styled';
import { useAppContext } from 'Context/AppContext';

interface NumberInHomeDataType {
  numberOfProposal: number;
  numberOfRequest: number;
}

export const OrgHome = () => {
  const navigate = useNavigate();
  const { currentOrg } = useAppContext();
  const [numberOfProposal, setNumberOfProposals] = useState<number>();
  const [numberOfRequest, setNumberOfRequest] = useState<number>();

  useEffect(() => {
    const GetNumberOfProposal = async () => {
      const response: ResponseDataType<NumberInHomeDataType> = await getRequest(
        `/${orgPath}/${currentOrg}/number-in-home`
      );

      if (response.code) {
        toast.error(response.message);
      } else {
        setNumberOfProposals(response.data?.numberOfProposal);
        setNumberOfRequest(response.data?.numberOfRequest);
      }
    };

    GetNumberOfProposal();
  }, [currentOrg, navigate]);

  return (
    <div className={styles.home_wrapper}>
      <Grid container spacing={5}>
        <Grid item sx={grid_item_sx}>
          <Link style={{ textDecoration: 'none' }} to='/proposal-list'>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Đề xuất</p>
              <p className={styles.card_number}>
                {numberOfProposal ?? (
                  <CircularProgress sx={circular_progress_sx} />
                )}
              </p>
              <Grid container justifyContent='flex-end'>
                <RateReviewOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
        <Grid item sx={grid_item_sx}>
          <Link style={{ textDecoration: 'none' }} to='/request-list'>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Đề nghị</p>
              <p className={styles.card_number}>
                {numberOfRequest ?? (
                  <CircularProgress sx={circular_progress_sx} />
                )}
              </p>
              <Grid container justifyContent='flex-end'>
                <RateReviewOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
      </Grid>
      <CreateStreamButton />
    </div>
  );
};
