import {
  DepartmentNamecode,
  departmentInfo,
  specialDepartment,
} from 'constant/department';

interface GetFullInfoInDepartment {
  isOldInfo: boolean;
  isManager: boolean;
  department?: {
    name: string;
    namecode: string;
  };
}

export const getFullInfoInDepartment = (params: GetFullInfoInDepartment) => {
  const { department, isManager, isOldInfo } = params;

  const prefix = isOldInfo ? 'Cựu ' : '';

  if (!department?.name) return 'Chưa tham gia phòng ban!';

  if (specialDepartment.includes(department.namecode as DepartmentNamecode))
    return prefix + department.name;

  if (Object.keys(departmentInfo).includes(department.namecode))
    return (
      prefix +
      departmentInfo[department.namecode as keyof typeof departmentInfo]
    );

  return (
    prefix +
    (isManager ? 'Trưởng phòng' : 'Nhân viên') +
    ' - Phòng ' +
    department.name
  );
};
