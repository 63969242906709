import { Badge, Menu } from '@mui/material';
import { NotificationInfo } from '../type';
import { QuestionAnswerOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { NotiItem } from './NotiItem';
import { useAppContext } from 'Context/AppContext';

interface ChatNotiDropdownPropType {
  numberOfChatNotiNotSeen: number;
  setNumberChatNotiOfNotSeen: React.Dispatch<React.SetStateAction<number>>;
  chatNotiList: NotificationInfo[];
  setChatNotiList: React.Dispatch<React.SetStateAction<NotificationInfo[]>>;
}

export const ChatNotiDropdown = (props: ChatNotiDropdownPropType) => {
  const {
    chatNotiList,
    numberOfChatNotiNotSeen,
    setChatNotiList,
    setNumberChatNotiOfNotSeen,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { onEvent, seenChatNotiRootId } = useAppContext();

  const handleClick = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setNumberChatNotiOfNotSeen(
      chatNotiList.reduce((value, cur) => (value += 1 - cur.status), 0)
    );
  }, [chatNotiList, setNumberChatNotiOfNotSeen]);

  useEffect(() => {
    if (!seenChatNotiRootId) return;

    setChatNotiList((prev) =>
      prev.map((chatNoti) => {
        if (seenChatNotiRootId !== chatNoti.root_id) return chatNoti;

        return {
          ...chatNoti,
          status: 1,
        };
      })
    );
  }, [seenChatNotiRootId, setChatNotiList]);

  useEffect(() => {
    onEvent('new-chat-notification', (newNoti) => {
      setChatNotiList((prev) => {
        prev = prev.filter((noti) => noti.id !== newNoti.id);

        return [
          {
            ...newNoti,
            status: seenChatNotiRootId === newNoti.root_id ? 1 : 0,
          },
          ...prev,
        ];
      });
    });
  }, [onEvent, setChatNotiList, seenChatNotiRootId]);

  return (
    <div>
      <Badge
        id='notificaiton-dropdown'
        badgeContent={numberOfChatNotiNotSeen}
        color='error'
        sx={{ margin: '3px 5px 0 15px', cursor: 'pointer' }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <QuestionAnswerOutlined />
      </Badge>
      <Menu
        id='account-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        autoFocus={false}
        MenuListProps={{
          'aria-labelledby': 'notificaiton-dropdown',
        }}
        PaperProps={{
          sx: {
            width: 320,
            right: 20,
            left: 'auto !important',
            borderRadius: '10px',
            padding: '5px',
            marginTop: '10px',
            boxShadow: 'none',
            border: 'solid 1px #cacaca',
            maxHeight: '400px',
          },
        }}
      >
        {chatNotiList.map((noti, index) => (
          <NotiItem
            key={index}
            handleCloseMenu={handleCloseMenu}
            noti={noti}
            handleSeeNoti={() => {}}
            isNormalNoti={false}
          />
        ))}
        {!chatNotiList.length && (
          <div style={{ fontSize: 18, fontWeight: 600, marginLeft: 10 }}>
            Bạn hiện chưa có thông báo nào
          </div>
        )}
      </Menu>
    </div>
  );
};
